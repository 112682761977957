import { parseSse } from './parseSse';

self.onmessage = async function (event) {
  const { readableStream } = event.data;

  try {
    await parseSse(readableStream, (parsedEvent) => {
      self.postMessage({ event: parsedEvent });
    });

    self.postMessage({ done: true });
  } catch (error) {
    self.postMessage({ error: error.message });
  }
};
