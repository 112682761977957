import { EventSourceParserStream } from 'eventsource-parser/stream';

export async function parseSse(
  readableStream: ReadableStream<Uint8Array>,
  onParsedEvent: (event: any) => void,
  onClose?: () => void
) {
  const reader = readableStream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  try {
    for (;;) {
      const { done, value: parsedEvent } = await reader.read();
      if (done) {
        break;
      }
      onParsedEvent(parsedEvent);
    }
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Unknown error');
  } finally {
    await reader.cancel(); // ensure cancellation
    reader.releaseLock(); // ensure reader is released
  }
}
